export default {
  // der farming/reward Token
  cake: { // GMT on blast 0xB60dF4A1db61B173d0d774ffa813C3c37070E38b
    81457: '0xFf08733849cf99408C21b0b968d7e3a724c9ec48',
    56: '0x3c3B33bA5d5e6Bcf4fe71000Bf0D282709f019d9', // adamant farm token
    97: '',
  },

  masterChef: {  // on blast global money MC 0x7133336A8340886F13ff35ED432F3490b4ee29DF
    81457: '0x642E775EA1223B337B8D19dBcc99ac7b6342C953',
    56: '0xE84848B8db8dB313041872354A288dF66A54e197', // adamant masterchef
    97: '',
  },

  mulltiCall: {  // on blast 0xf48e6c751bfcC111eDb33804E9aF013aC4Cbf8FB
    81457: '0xf48e6c751bfcC111eDb33804E9aF013aC4Cbf8FB',
    56: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb', 
    97: '',
  },


  wbnb: { // weth on blast 0x4300000000000000000000000000000000000004
    81457: '0x4300000000000000000000000000000000000004',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },

  busd: { // USDB on blast 0x4300000000000000000000000000000000000003
    81457: '0x4300000000000000000000000000000000000003',
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },    

  btc: { // BTC on blast 0xF7bc58b8D8f97ADC129cfC4c9f45Ce3C0E1D2692
    81457: '0xF7bc58b8D8f97ADC129cfC4c9f45Ce3C0E1D2692',
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },    
  




}